import React, { FC } from "react"
import { useInput } from "./useInput"
import style from "./input.module.scss"

// Set up props for the input field
interface inputProps {
  label?: string
  placeholder?: string
  validationRegex?: RegExp
  required?: boolean
  errorMessage?: string
  name: string
}

const Input: FC<inputProps> = ({
  label,
  placeholder,
  validationRegex,
  required,
  errorMessage,
  name,
}) => {
  // Exctract nessesary data from the input hook
  const { value, handleChange, showError, validationFunction } = useInput({
    validationRegex,
    required,
    name,
  })

  return (
    <label className={style.label}>
      {label && <span className={style.labelText}>{label}</span>}
      <input
        className={`${style.input} ${showError ? style.hasError : ""}`}
        value={value}
        id={name}
        onChange={handleChange}
        onBlur={validationFunction}
        placeholder={placeholder}
      />
      {/* Show error message if nessesary */}
      {showError && (
        <span className={style.errorMessage}>
          {errorMessage || "Please provide a valid input"}
        </span>
      )}
    </label>
  )
}

export default Input
