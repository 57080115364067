import React, { useContext, useEffect } from "react"
import Card from "../components/containers/card/card"
import Layout, { LayoutContext } from "../layout/layout"
import style from "./homepage.module.scss"
import { graphql } from "gatsby"
import cardStyle from "../components/containers/card/card.module.scss"
import Button from "../components/utilities/buttons/primary-button/primaryButton"
import Input from "../components/utilities/form/input/input"
import Form from "../components/utilities/form/form"
import { useSubmitUser } from "../components/hooks/submitUser"
import SEO from "../components/utilities/seo/SEO"
import { Helmet } from "react-helmet"
import GatsbyImage from "gatsby-image"
import StrawberryHeader from "../components/misc/straberry-release/straberryHeader"

// Test clone v2 branch -- From Aiden

const getFluidImage = (obj: any) => obj.childImageSharp.fluid

// Home page component
const HomePage = ({ data }: any) => {
  const { allPackaging, bannerImg, newLemon, dog, mapImage } = data

  // Handle the user capture form with the useSubmitUser hook
  const { submitUser, emailSubmitted, resetEmailSubmitted } = useSubmitUser()

  // page schema markup
  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "OCD",
    image: "https://ocdonline.co.uk/images/ocd/OCD.png",
    "@id": "",
    url: "https://ocdonline.co.uk",
    telephone: "01224748613",
    address: {
      "@type": "PostalAddress",
      streetAddress: "",
      addressLocality: "Walsall",
      postalCode: "WS1 2",
      addressCountry: "GB",
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      opens: "00:00",
      closes: "23:59",
    },
    sameAs: [
      "https://www.facebook.com/OdourControlDevelopments/",
      "https://www.instagram.com/ocdonline",
    ],
  }

  return (
    <Layout title="OCD" titleDisappearOnScroll className={style.homepage} darkHeader>
      <Helmet>
        <meta
          name="google-site-verification"
          content="WOuuTQdpZewfIojEF2trtnQl_xukL3vyxIB4ESdK4u8"
        />
      </Helmet>
      <SEO
        lang="en"
        title="OCD | Odour Control Developments"
        description="OCD DEO-MAX | Make the world smell great"
        image="https://ocdonline.co.uk/images/ocd/OCD.png"
        keywords="OCD, Odour Control Developments, OCD DEO-MAX"
        schemaMarkup={schema}
        meta
      />
      <StrawberryHeader img={getFluidImage(bannerImg)} />
      <div className={style.content}>
        <Card
          title="OCD DEO-MAX"
          subtitle="Make the world smell great."
          img={getFluidImage(allPackaging)}
          alt="OCD all packaging collated"
          imageSharp
          linkText="Explore Products"
          href="/odour-neutraliser-products/"
        />
        <Card img={getFluidImage(newLemon)} imageSharp alt="OCD Lemon spray">
          {!emailSubmitted ? (
            <Form
              name="homepage-email-capture"
              handleSubmit={values => submitUser(values)}
              className={style.form}
            >
              <p>Get reminded when we release a new fragrance.</p>
              <Input
                name="email"
                label="Enter your email"
                placeholder="sales@ocdonline.co.uk"
                validationRegex={
                  /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
                }
                errorMessage="Are you sure that’s the right email?"
              />
              <Button onClick={() => null} className={style.submit}>
                Remind Me
              </Button>
            </Form>
          ) : (
            <div className={style.formSubmitted}>
              <p>
                Welcome aboard. We’ll email you when there’s a new OCD
                fragrance.
              </p>
              <Button
                className={style.retryButton}
                onClick={resetEmailSubmitted}
              >
                Use another email
              </Button>
            </div>
          )}
        </Card>

        <Card
          img={getFluidImage(dog)}
          imageSharp
          alt="OCD cube orange"
          body="All of our products replace bad smells with good smells. Find out how OCD can eliminate bad smells from your life."
          linkText="Discover Applications"
          href="/applications/"
        />
        <Card
          img={getFluidImage(mapImage)}
          imageSharp
          alt="OCD aerosol Bubblegum flavour"
          body="Buy your smell solution today."
          linkText="Find a Retailer"
          href="/retailers/"
        />
      </div>
    </Layout>
  )
}
export default HomePage

// Query for home page images
export const query = graphql`
  {
    allPackaging: file(
      relativePath: { eq: "OCD All Packaging Collated-2-Tiny.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerImg: file(relativePath: { eq: "banner-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mapImage: file(relativePath: { eq: "map-screenshot.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bubblegumAero: file(relativePath: { eq: "applications-page-aerosol.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    freshLinenGel: file(relativePath: { eq: "type-page-cube.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dog: file(relativePath: { eq: "ocd-odour-neutralising-cube-with-dog.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newLemon: file(relativePath: { eq: "spray-windowsil.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
