import { useState } from "react"

interface formHookProps {
  handleSubmit: (values: any) => void
}

export interface formHookReturns {
  values: any
  setValues: React.Dispatch<React.SetStateAction<object>>
  setTotalErrors: React.Dispatch<React.SetStateAction<number>>
  showErrors: number
  handleError: (hasError: boolean) => void
  internalHandleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

export const useForm = ({ handleSubmit }: formHookProps): formHookReturns => {
  // Form values
  const [values, setValues] = useState<object>({})
  // Hold the total number of errors
  const [totalErrors, setTotalErrors] = useState<number>(0)
  // Hold whether to show errors
  const [showErrors, setShowErrors] = useState<number>(0)

  // Function to handle errors
  const handleError = (hasError: boolean) => {
    //   Subtract or add to the total errors state
    setTotalErrors(total => (hasError ? total + 1 : total > 0 ? total - 1 : 0))
  }

  // Run internal function to submit form before the given function - check for errors
  const internalHandleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Avoid reloading
    event.preventDefault()
    if (totalErrors > 0) {
      // Trigger errors to show after user submits (if there are any errors)
      setShowErrors(errors => errors + 1)
    } else {
      // If no errors submit the form
      showErrors && setShowErrors(0)
      handleSubmit(values)
    }
  }

  return {
    values,
    setValues,
    setTotalErrors,
    showErrors,
    handleError,
    internalHandleSubmit,
  }
}
