import React, { createContext, FC, ReactNode } from "react"
import { useForm, formHookReturns } from "./useForm"

// Set up form props
interface formProps {
  handleSubmit: (values: any) => void
  name: string
  children: ReactNode
  successMessage?: string
  className?: string
}

// Initialize form context
export const FormContext = createContext<Partial<formHookReturns>>({})

const Form: FC<formProps> = ({ handleSubmit, name, children, className }) => {
  // Bring in form logic from form hook
  const formHook = useForm({ handleSubmit })
  // Extract nessesary data from hook
  const { internalHandleSubmit } = formHook
  return (
    // Wrap in context provider to access within inputs
    <FormContext.Provider value={formHook}>
      <form name={name} onSubmit={internalHandleSubmit} className={className}>
        {children}
      </form>
    </FormContext.Provider>
  )
}

export default Form
