import React, { FC, ReactNode } from "react"
import PatternBg from "../../misc/pattern-bg/patternBg"
import LinkButton from "../../utilities/buttons/link-button/linkButton"
import style from "./card.module.scss"
import { useInView } from "react-intersection-observer"
import GatsbyImage from "gatsby-image"

interface cardProps {
  title?: string
  subtitle?: string
  body?: string
  img?: any
  alt?: string
  href?: string
  linkText?: string
  imageSharp?: boolean
  children?: ReactNode
}

const Card: FC<cardProps> = ({
  title,
  subtitle,
  body,
  img,
  alt,
  href,
  linkText,
  imageSharp,
  children,
}) => {
  // Hold whether visible in viewport
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <div className={`${style.card} ${inView ? style.visible : ""}`} ref={ref}>
      <div className={style.backdrop} />
      <div className={style.content}>
        <div className={style.cardLeft}>
          {title && <h2>{title}</h2>}
          {subtitle && <h4>{subtitle}</h4>}
          {/* Change layout dependant on desktop or mobile */}
          <div className={style.desktopOnly}>
            {body && <p>{body}</p>}
            {href && (
              <LinkButton href={href} center>
                {linkText || "Explore"}
              </LinkButton>
            )}
            {children && <div>{children}</div>}
          </div>
        </div>

        <div className={style.cardRight}>
          {img && (
            <div className={style.imgWrapper}>
              {imageSharp ? (
                <GatsbyImage fluid={img} alt={alt} className={style.img} />
              ) : (
                <img loading="lazy" src={img} alt={alt} />
              )}
            </div>
          )}

          {/* Change layout dependant on desktop or mobile */}
          {body && <p className={style.mobileOnly}>{body}</p>}
          <div className={style.mobileOnly}>
            {href && (
              <LinkButton href={href} center>
                {linkText || "Explore"}
              </LinkButton>
            )}
          </div>
          {children && <div className={style.mobileOnly}>{children}</div>}
        </div>
      </div>
    </div>
  )
}

export default Card
