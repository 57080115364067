import { useLocalStorage } from "./localStorage"
export const useSubmitUser = () => {
  // Hold whether the user capture form has been submitted
  const [
    emailSubmitted,
    setEmailSubmitted,
    resetEmailSubmitted,
  ] = useLocalStorage("emailSubmitted")

  const submitUser = async (values: any) => {
    // extract email address from values
    const { email } = values
    // Trigger lamda function for subscribing a user
    const response = await fetch("/api/user-subscribe", {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    })
    // Set internal state that the user has subscribed
    if (response.status === 200) setEmailSubmitted(true)
  }

  return {
    emailSubmitted,
    submitUser,
    resetEmailSubmitted,
  }
}
