import React from 'react'
import style from './style.module.scss'
import GatsbyImage from 'gatsby-image'
import LinkButton from '../../utilities/buttons/link-button/linkButton'

const StrawberryHeader = ({ img }:any) => {
  return (
    <div className={style.strawberry_header_wrap}>
      
      <div className={style.strawberry_header_inner_wrap}>
        <div className={style.strawberry_header_inner}>
          <div className={style.text_col}>
            <h1>OCD DEO-MAX</h1>
            <p>The new <strong>Strawbs’N’Cream</strong> fragrance!</p>
            <div className={style.btn_wrap}>
              <LinkButton href='/odour-neutraliser-products/' center color='white'>
                View Strawbs’N’Cream
              </LinkButton>
            </div>
          </div>
          <div className={style.img_col}>
            <div className={style.image_wrap}>
              <GatsbyImage fluid={img} />
            </div>
            
          </div>
        </div>

        <div className={style.shapeDiv}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className={style.shape_fill}></path>
          </svg>
        </div>
      </div>

    </div>
  )
}
export default StrawberryHeader