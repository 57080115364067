import { useContext, useEffect, useState } from 'react';
import { FormContext } from '../form';

interface inputHookProps {
  validationRegex?: RegExp;
  required?: boolean;
  name: string;
}

interface inputHookReturns {
  value: string;
  handleChange: any;
  showError: boolean;
  validationFunction: any;
}

export const useInput = (props?: inputHookProps): inputHookReturns => {
  const { validationRegex, required, name } = props || {};
  const { showErrors, handleError, setValues } = useContext(FormContext);
  const [value, setValue] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const handleChange = (event: any) => {
    const newValue: string = event.currentTarget.value;
    setValue(newValue);
  };

  const runValidation = () => {
    if (validationRegex) {
      setHasError(value.search(validationRegex) === -1 ? true : false);
    } else if (required) {
      setHasError(value.length > 0 ? false : true);
    }
  };

  const runValidationAndShowErrors = () => {
    setShowError(hasError);
  };

  useEffect(() => {
    handleError(hasError);
  }, [hasError]);

  useEffect(() => {
    setShowError(showErrors > 0 ? true : false);
  }, [showErrors]);

  useEffect(() => {
    runValidation();
    setValues((values) => ({ ...values, [name]: value }));
  }, [value]);

  return {
    value: value,
    handleChange: handleChange,
    showError: showError && hasError,
    validationFunction: runValidationAndShowErrors,
  };
};
